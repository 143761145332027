<template>
  <div class="first-page">
    <div class="page-msg">{{ $t('message.main.firstPageMsg') }}</div>
    <div class="main-divein">
      <video
        id="myVideo"
        class="background-video"
        src="@/assets/video.mp4"
        webkit-playsinline
        playsinline
        muted
        autoplay
        loop
      />
    </div>
    <div class="page-tip">
      {{ $t('message.main.firstPageTip') }}
    </div>
    <a class="download-btn" href="https://play.google.com/store/apps/details?id=com.mitv.tvhome.mitvplus" target="_blank">
      <span>{{ $t('message.header.download') }}</span>
      <img src="@/assets/go-link.png" alt="">
    </a>
  </div>
</template>

<script>
export default {
  name: 'FirstPage',
}
</script>

<style lang="scss" scoped>
.first-page{
  width: 1080px;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #000000;
  position: relative;

  .page-msg{
    color: #FFFFFF;
    width: 751px;
    height: 200px;
    font-family: MiSans;
    font-style: normal;
    font-weight: bold;
    font-size: 80px;
    line-height: 100px;
    margin-top: 473px;
    margin-bottom: 115px;
  }
  .main-divein{
    width: 100%;
    overflow: hidden;
    .background-video{
      width: 100%;
      height: 100%;
    }
    video{
      width: 100%;
    }
  }
  .page-tip{
    width: 840px;
    color: #FFFFFF;
    font-family: MiSans;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    margin-top: 100px;
  }
  .download-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FF6800;
    border-radius: 36px;
    cursor: pointer;
    margin-top: 83px;
    span{
      font-weight: 400;
      color: #FFFFFF;
      font-size: 24px;
      margin: 12px 23px;
    }
    img{
      width: 24px;
      height: 24px;
      margin-right: 25px;
    }
  }
}

</style>
