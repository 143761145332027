<template>
  <div class="desktop-wrap">
    <div class="header" :class="headerFixed ? 'isFixed' : ''">
      <HeaderTop />
      <HeaderMain />
      <HeaderNav />
    </div>
    <div class="main">
      <FirstPage />
      <DiveIn />
      <MainImg />
    </div>
    <div class="footer">
      <GetTv />
      <Faqs />
    </div>
  </div>
</template>

<script>
import HeaderTop from './headerTop.vue'
import HeaderMain from './headerMain.vue'
import HeaderNav from './headerNav.vue'
import FirstPage from './firstPage.vue'
import DiveIn from './diveIn.vue'
import MainImg from './mainImg.vue'
import Faqs from './faqs.vue'
import GetTv from './getTv.vue'
export default {
  data() {
    return {
      curIndex:'',
      headerFixed: false,
    }
  },
  components:{
    HeaderTop,
    HeaderMain,
    HeaderNav,
    FirstPage,
    DiveIn,
    MainImg,
    Faqs,
    GetTv
  },
  mounted() {
    window.addEventListener('scroll',this.throttle(this.handleScroll, 500), true)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    handleScroll() {
      // 得到页面滚动的距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      // 当滚动超过1080px时，实现吸顶效果。
      if (scrollTop > 1025) {
        this.headerFixed = true
      } else {
        this.headerFixed = false
      }
    },
    throttle(fn, delay) {
      let timer = null
      return function() {
        if (timer) {
            return
        }
        timer = setTimeout(() => {
            fn.apply(this, arguments)
            timer = null
        },delay)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.desktop-wrap{
  width: 100%;
  min-width: 1200px;
  position: relative;
}
.header {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
.isFixed{
  width: 100%;
  position: fixed;
  top: 0;
  .header-top-wrap{
    background-color: rgba($color: #424245, $alpha: 0.9);
  }
  .header-main-wrap{
    background-color: rgba($color: #202020, $alpha: 0.9);
  }
  .header-nav-wrap{
    background-color: rgba($color: #202020, $alpha: 0.9);
  }
}
</style>