<template>
  <div class="main-divein">
    <video
      class="background-video"
      src="@/assets/video.mp4"
      muted
      autoplay
      loop
    />
  </div>
</template>

<script>
export default {
  name: 'DiveIn',
}
</script>

<style lang="scss" scoped>
.main-divein{
  width: 100%;
  overflow: hidden;
  .background-video{
    width: 100%;
    height: 100%;
  }
}

</style>
