<template>
  <div v-if="showTop" class="header-top-wrap">
    <div class="header-tip" >
      {{ $t('message.header.titleTip') }}
      <img @click="hideTop" class="close-btn" src="@/assets/close.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderTop',
  data() {
    return {
      showTop: true,
    }
  },
  methods: {
    hideTop () {
      this.showTop = false
    }
  },
}
</script>

<style lang="scss" scoped>
.header-top-wrap{
  width: 100%;
  height: 75px;
  background: #424245;
  line-height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  .header-tip{
    color: #FFFFFF;
    font-family: MiSans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    position: relative;
  }
  .close-btn{
    width: 15px;
    height: 15px;
    cursor: pointer;
    position: absolute;
    top: 30px;
    right: -30px;
  }
}

</style>
