module.exports = {
  message: {
    header: {
      titleTip: "Access a growing library of premium live channels of news, movies, entertainment, reality, and more. Watch free anytime, anywhere. No subscription needed.",
      download: "DOWNLOAD THE APP",
      bloom_berg: "News",
      tasteMade: "Food",
      rakutenViki: "Movie",
      loupe: "Entertainment",
      myZenFit: "Fitness",
      failarmy: "Comedy",
      toonGoggles: "Kids",
      whatTv: "What's MiTV+",
      channels: "Channels",
      watchFree: "Watch free",
      faqs: "FAQs"
    },
    main: {
      seeMoreChannels: "SEE MORE CHANNELS",
      firstPageMsg: "Make TV free for everyone.",
      firstPageTip: "Access a growing library of premium live channels of news, movies, entertainment, reality, and more. Watch free anytime, anywhere. No subscription needed.",
      bloom_bergTextMsg: "Never misses a beat.",
      bloom_bergTextTip: "Real-time breaking news and unbiased opinions from trusted sources 24/7.",
      tastemadeTextMsg: "Eat the best, leave the rest.",
      tastemadeTextTip: "Still wondering what you want for dinner? Get inspirations from the best recipes.",
      rakutenTextMsg: "Back to the classics.",
      rakutenTextTip: "Drop in to watch timeless titles of comedy, thriller, drama any time. Looking for K-drama? We've got you covered for movie night.",
      loupeTextMsg: "Vibe check.",
      loupeTextTip: "Avant-garde art exhibitions in your living room.",
      myzenfitTextMsg: "Feel the burn.",
      myzenfitTextTip: "From pilates to tai-chi, join sought-after workout sessions from home.",
      failarmyTextMsg: "Laughter supply 24/7.",
      failarmyTextTip: "That one channel you can space out to.",
      toonGogglesTextMsg: "Take a break from parenting.",
      toonGogglesTextTip: "Let the kids decide!",
      
    },
    footer: {
      getTVTitle: "Get MiTV+ today!",
      getTVFootertitle: "Now available on",
      miBox: "Mi box",
      miTv: "Mi TV",
      miStick: "Mi stick",
      comingSoon: "Coming soon...",
      faqsHeader: "FAQs",
      questionOne: "What is MiTV+?",
      answerOne: "MiTV+ is Xiaomi's free ad-supported TV streaming service. Enjoy a growing selection of premium live channels of news, movies, entertainment, reality, and more. Watch free anytime, anywhere. No subscription needed.",
      questionTwo: "What device is MiTV+ available on?",
      answerTwo: "MiTV+ is available on all Android TV.",
      questionThree: "Is MiTV+ really free?",
      answerThree: "Yes, advertisements allow us to keep things free.",
      questionFour: "Is there a paid subscription for MiTV+?",
      answerFour: "No, we don't offer paid subscriptions at this moment.",
      questionFive: "Do I need to create a Xiaomi account?",
      answerFive: "No, you can watch free TV without a Xiaomi account. If you choose to create one, you can save all your favorite channels in one place and receive personalized recommendations.",
      questionSix: "Does MiTV+ require an Internet connection?",
      answerSix: "Yes, MiTV+ delivers free live TV channels via the Internet.",
    }
  },
}
