<template>
  <div class="main-img">
    <div v-for="(item, index) in imgList" :key="index" class="main-img-getItem">
      <img class="main-bg-img" :src="require('@/assets/desktop/' + item.img)" alt="">
      <div class="main-bg-text-wrap">
        <div class="main-bg-text">
          <div class="main-bg-text-left">
            <div class="main-bg-msg">{{ $t(`message.main.${item.name}TextMsg`) }}</div>
            <div class="main-bg-tip">{{ $t(`message.main.${item.name}TextTip`) }}</div>
            <div class="main-bg-btn">
              <span>{{ $t('message.main.seeMoreChannels') }}</span>
              <img src="@/assets/arrow-right.png" alt="">
            </div>
          </div>
          <img class="main-bg-logo" :src="require('@/assets/desktop/' + item.logoImg)" alt="">
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainImg',
  data() {
    return {
      imgList:[
        {
          name:'bloom_berg',
          img:'',
          logoImg: ''
        },
        {
          name: 'tastemade',
          img:'',
          logoImg: ''
        },
        {
          name: 'rakuten',
          img:'',
          logoImg: ''
        },
        {
          name: 'loupe',
          img:'',
          logoImg: ''
        },
        {
          name: 'myzenfit',
          img:'',
          logoImg: ''
        },
        {
          name: 'failarmy',
          img:'',
          logoImg: ''
        },
        {
          name: 'toonGoggles',
          img:'',
          logoImg: ''
        },
      ],

    }
  },
  created() {
    this.imgList.forEach(item => {
      item.img = `${item.name}.png`
      item.logoImg = `${item.name}_logo.png`
    })
  }
}
</script>

<style lang="scss" scoped>
.main-img{
  width: 100%;
  overflow: hidden;
  .main-img-getItem{
    width: 100%;
    position: relative;
    .main-bg-img{
      width: 100%;
    }
    .main-bg-text-wrap{
      width: 100%;
      position: absolute;
      left: 0px;
      bottom: 90px;
    }
    .main-bg-text{
      margin: 0 auto;
      width: 1080px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .main-bg-text-left{
        max-width: 590px;
      }
      .main-bg-msg{
        font-family: MiSans;
        font-weight: 700;
        font-style: normal;
        font-size: 36px;
        text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
        color: #FFFFFF;
      }
      .main-bg-tip{
        font-family: MiSans;
        font-weight: 400;
        font-style: normal;
        font-size: 16px;
        text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
        color: #FFFFFF;
        line-height: 29px;
        margin-bottom: 20px;
      }
      .main-bg-btn{
        width: 252px;
        height: 30px;
        border: 2px solid #FFFFFF;
        border-radius: 36px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 14px;
        box-sizing: border-box;
        cursor: pointer;
        span{
          font-family: MiSans;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          letter-spacing: 0px;
        }
        img{
          width: 7px;
          height: 14px;
        }
      }
    }
  }
}

</style>
