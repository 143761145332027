<template>
  <div class="header-main-wrap">
    <div class="header-main-inner">
      <img src="@/assets/logo.png" alt="">
      <a class="download-btn" href="https://play.google.com/store/apps/details?id=com.mitv.tvhome.mitvplus" target="_blank">
        <div>{{ $t('message.header.download') }}</div>
        <img style="width:18px;height:18px" src="@/assets/go-link.png" alt="">
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderMain',
}
</script>

<style lang="scss" scoped>
.header-main-wrap{
  width: 100%;
  height: 90px;
  background: #202020;
  display: flex;
  justify-content: center;
  align-items: center;
  .header-main-inner{
    width: 1080px;
    height: 89px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #979797;
    color: #fff;
    .download-btn{
      display: flex;
      align-items: center;
      padding: 0 23px;
      justify-content: space-between;
      width: 247px;
      height: 37px;
      border: 1px solid #979797;
      border-radius: 36px;
      cursor: pointer;
      box-sizing: border-box;
      div{
        height: 21px;
        line-height: 21px;
        color: #FFFFFF;
        font-size: 16px;
      }
      img{
        width: 18px;
        height: 18px;
      }
    }
    .download-btn:hover{
      background: #FF6800;
      border: 1px solid #202020;
    }
  }
  
}

</style>
