<template>
  <div class="faqs-wrap">
    <div class="faqs-header">{{ $t('message.footer.faqsHeader') }}</div>
    <ul class="faqs-main">
      <li class="faq-item" v-for="(qa, index) in faqList" :key="index">
        <div class="faq-question">
          <span>{{ qa.question }}</span>
          <img @click="showAnswer($event,index)" src="@/assets/arrow-up.svg" alt="">
        </div>
        <transition name="slide-fade">
          <div class="faq-answer" v-if="qa.isShowAnswer">
            {{ qa.answer }}
          </div>
        </transition>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Faqs',
  data() {
    return {
      faqList: [
        {
          question: this.$t('message.footer.questionOne'),
          answer: this.$t('message.footer.answerOne'),
          isShowAnswer: false,
        },
        {
          question: this.$t('message.footer.questionTwo'),
          answer: this.$t('message.footer.answerTwo'),
          isShowAnswer: false,
        },
        {
          question: this.$t('message.footer.questionThree'),
          answer: this.$t('message.footer.answerThree'),
          isShowAnswer: false,
        },
        {
          question: this.$t('message.footer.questionFour'),
          answer: this.$t('message.footer.answerFour'),
          isShowAnswer: false,
        },
        {
          question: this.$t('message.footer.questionFive'),
          answer: this.$t('message.footer.answerFive'),
          isShowAnswer: false,
        },
        {
          question: this.$t('message.footer.questionSix'),
          answer: this.$t('message.footer.answerSix'),
          isShowAnswer: false,
        },
      ]
    }
  },
  methods: {
    showAnswer(event,index) {
      if(this.faqList[index].isShowAnswer){
        event.target.style.transform = 'rotate(0deg)'
      }else{
        event.target.style.transform = 'rotate(180deg)'
      }
      event.target.style.transition = 'all 0.7s'
      this.faqList[index].isShowAnswer = !this.faqList[index].isShowAnswer
    }
  },
}
</script>

<style lang="scss" scoped>
.faqs-wrap{
  width: 100%;
  height: 933px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  .faqs-header{
    width: 158px;
    height: 80px;
    color: #000000;
    font-family: MiSans;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 80px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 76px;
    margin-bottom: 47px;
  }
  .faqs-main{
    margin-bottom: 103px;
  }
  .faq-item{
    width: 1081px;
    padding: 25px 0px;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.3);
    .faq-question{
      display: flex;
      align-items: center;
      justify-content: space-between;
      span{
        font-family: MiSans;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0px;
        text-align: left;
      }
      img{
        cursor: pointer;
      }
    }

    .faq-answer{
      width: 1038px;
      margin-top: 30px;
      font-family: MiSans;
      color: rgba($color: #000000, $alpha: 0.8);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0px;
      text-align: left;
    }
    
  }

  .slide-fade-enter-active {
    transition: all .5s ease;
  }
  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateY(10px);
    opacity: 0;
  }
}
</style>