<template>
  <div class="mobile-wrap">
    <div class="header">
      <HeaderTop />
      <HeaderMain />
      <HeaderNav :mySwiper="mySwiper"/>
    </div>
    <div class="main">
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide class="swiper-slide-item">
          <div class="main-first">
            <FirstPage />
          </div>
        </swiper-slide>

        <swiper-slide class="swiper-slide-item main-img-getItem-wrap" v-for="(item, index) in imgList" :key="index" >
          <div class="main-img-getItem">
            <img class="main-bg-img" :src="require('@/assets/mobile/' + item.img)" alt="">
            <img class="main-bg-logo" :class="`${item.name}-logo`" :src="require('@/assets/desktop/' + item.logoImg)" alt="">
            <div class="main-bg-text-bom">
              <div class="main-bg-msg">{{ $t(`message.main.${item.name}TextMsg`) }}</div>
              <div class="main-bg-tip">{{ $t(`message.main.${item.name}TextTip`) }}</div>
              <a class="main-bg-btn">
                <span>{{ $t('message.main.seeMoreChannels') }}</span>
                <img src="@/assets/arrow-right.png" alt="">
              </a>
            </div>
          </div>
        </swiper-slide>
        
        <swiper-slide class="swiper-slide-item">
          <GetTv />
        </swiper-slide>
        
        <swiper-slide class="swiper-slide-item">
          <Faqs />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import HeaderTop from './headerTop.vue'
import HeaderMain from './headerMain.vue'
import HeaderNav from './headerNav.vue'
import FirstPage from './firstPage.vue'
import GetTv from './getTv.vue'
import Faqs from './faqs.vue'

export default {
  components:{
    Swiper,
    SwiperSlide,
    HeaderTop,
    HeaderMain,
    HeaderNav,
    FirstPage,
    GetTv,
    Faqs,
  },
  data() {
    return {
      // screenHeight: 0,
      swiperOptions: {
        direction: 'vertical',
        pagination: {
          el: '.swiper-pagination',
        },
        // followFinger: false,
        // autoplay: {
        //   delay:3000,
        //   stopOnLastSlide:false,
        //   disableOnInteraction: false,
        // },
      },
      imgList:[
        {
          name:'bloom_berg',
          img:'',
          logoImg: ''
        },
        {
          name: 'tastemade',
          img:'',
          logoImg: ''
        },
        {
          name: 'rakuten',
          img:'',
          logoImg: ''
        },
        {
          name: 'loupe',
          img:'',
          logoImg: ''
        },
        {
          name: 'myzenfit',
          img:'',
          logoImg: ''
        },
        {
          name: 'failarmy',
          img:'',
          logoImg: ''
        },
        {
          name: 'toonGoggles',
          img:'',
          logoImg: ''
        },
      ],
      mySwiper: null,
    }
  },
  created() {
    this.init()
  },
  mounted () {
    this.mySwiper = this.$refs.mySwiper.$swiper
  },
  
  methods: {
    init() {
      this.imgList.forEach(item => {
        item.img = `${item.name}.png`
        item.logoImg = `${item.name}_logo.png`
      })
      const height = document.documentElement.clientHeight
      
      this.swiperOptions.height = height
    },
  },
}
</script>

<style lang="scss" scoped>
.mobile-wrap{
  width: 1080px;
  position: relative;
  // overflow: hidden;
  .header{
    width: 960px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    .header-top-wrap{
      background-color: rgba($color: #424245, $alpha: 0.9);
    }
    .header-main-wrap{
      background-color: rgba($color: #202020, $alpha: 0.9);
    }
    .header-nav-wrap{
      background-color: rgba($color: #202020, $alpha: 0.9);
    }
  }
  .main{
    width: 1080px;
    height: 100vh;
    overflow: hidden;
    .swiper-slide-item{
      height: 100vh;
    }
    .main-img-getItem-wrap{
      width: 1080px;
      height: 100vh;
      position: relative;
      .main-img-getItem{
        width: 1080px;
        height: 100vh;
        overflow: hidden;

        .main-bg-img{
          width: 1080px;
          height: 100%;
        }

        .main-bg-logo{
          position: absolute;
          top: 230px;
          left: 59px;
        }
        .bloom_berg-logo{
          width: 242px;
          height: 45px;
        }
        .tastemade-logo{
          width: 203px;
          height: 120px;
        }
        .rakuten-logo{
          width: 207px;
          height: 58px;
        }
        .loupe-logo{
          width: 242px;
          height: 102px;
        }
        .myzenfit-logo{
          width: 131px;
          height: 131px;
        }
        .failarmy-logo{
          width: 202px;
          height: 36px;
        }
        .toonGoggles-logo{
          width: 153px;
          height: 88px;
        }

        .main-bg-text-bom{
          max-width: 840px;
          position: absolute;
          left: 59px;
          bottom: 100px;
          .main-bg-msg{
            font-family: MiSans;
            font-weight: 700;
            font-style: normal;
            font-size: 50px;
            text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
            color: #FFFFFF;
          }
          .main-bg-tip{
            font-family: MiSans;
            font-weight: 400;
            font-style: normal;
            font-size: 36px;
            text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
            color: #FFFFFF;
            line-height: 48px;
            margin-bottom: 58px;
          }
          .main-bg-btn{
            border: 2px solid #FFFFFF;
            border-radius: 36px;
            cursor: pointer;
            position: relative;
            span{
              font-family: MiSans;
              color: #FFFFFF;
              font-size: 36px;
              font-style: normal;
              font-weight: 400;
              margin: 12px 51px 12px 17px;
            }
            img{
              position: absolute;
              top: 50%;
              left: 94%;
              transform: translate(-50%, -50%);
              width: 15px;
              height: 25px;
            }
          }
        }
      }
    }
  }
  
}
</style>