<template>
  <div id="app">
    <Mobile v-if="isMobile" />
    <DeskTop v-else/>
  </div>
</template>

<script>
import DeskTop from '@/components/desktop'
import Mobile from '@/components/mobile'

export default {
  name: 'App',
  components: {
    DeskTop,
    Mobile
  },
  data() {
    return {
      isMobile: false
    }
  },

  created() {
    this.checkIsMobile()
    window.addEventListener('resize', this.checkIsMobile, false)
  },

  methods: {
    checkIsMobile() {
      this.isMobile = document.documentElement.clientWidth < 1200
    }
  }
}
</script>

<style>
  @import './styles/reset.css';
</style>
