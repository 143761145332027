<template>
  <div class="first-page">
    <div class="first-page-tip">
      <div class="page-msg">{{ $t('message.main.firstPageMsg') }}</div>
      <div class="page-tip">
        {{ $t('message.main.firstPageTip') }}
      </div>
    </div>
    <a class="download-btn" href="https://play.google.com/store/apps/details?id=com.mitv.tvhome.mitvplus" target="_blank">
      <span>{{ $t('message.header.download') }}</span>
      <img style="width:24px;height:24px" src="@/assets/go-link.png" alt="">
    </a>
  </div>
</template>

<script>
export default {
  name: 'FirstPage',
}
</script>

<style lang="scss" scoped>
.first-page{
  width: 100%;
  height: 1025px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #000000;
  z-index: 1;
  .first-page-tip{
    width: 990px;
    .page-msg{
      font-family: MiSans;
      color: #FFFFFF;
      width: 990px;
      height: 291px;
      font-style: normal;
      font-weight: bold;
      font-size: 130px;
      line-height: 139px;
      margin-top: 104px;
    }
    .page-tip{
      width: 901px;
      height: 85px;
      color: #FFFFFF;
      font-style: normal;
      font-weight: normal;
      font-size: 21px;
      margin-top: 40px;
    }
  }
  .download-btn{
      display: flex;
      align-items: center;
      padding: 0 23px;
      justify-content: space-between;
      width: 360px;
      height: 55px;
      background: #000000;
      border: 2px solid #FFFFFF;
      border-radius: 36px;
      cursor: pointer;
      margin-top: 82px;
      box-sizing: border-box;
      span{
        font-weight: 400;
        color: #FFFFFF;
        font-size: 24px;
      }
      img{
        width: 24px;
        height: 24px;
      }
    }
    .download-btn:hover{
      background: #FF6800;
      border: 2px solid #000000;
    }
}

</style>
