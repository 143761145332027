<template>
  <div class="get-tv-wrap">
    <div class="get-tv">
      <div class="get-tv-title">{{ $t('message.footer.getTVTitle') }}</div>
      <a class="goto-link" href="https://play.google.com/store/apps/details?id=com.mitv.tvhome.mitvplus" target="_blank">
        <img src="@/assets/googlePlay.png" alt="">
      </a>
      <div>
        <TvSwiper />
      </div>
      <div class="get-tv-footer">
        <div class="get-tv-footer-title">{{ $t('message.footer.getTVFootertitle') }}</div>
        <div class="get-tv-footer-line"></div>
        <div class="get-tv-footer-icon">
          <a class="icon-wrap" href="https://www.mi.com/global/mi-box-s" target="_blank">
            <img src="@/assets/desktop/miBox.png" alt="">
            <div class="get-tv-icon-text">{{ $t('message.footer.miBox') }}</div>
          </a>
          <a class="icon-wrap" href="https://www.mi.com/global/product/xiaomi-tv-p1e-55/" target="_blank">
            <img src="@/assets/desktop/miTv.png" alt="">
            <div class="get-tv-icon-text">{{ $t('message.footer.miTv') }}</div>
          </a>
          <a class="icon-wrap" href="https://www.mi.com/global/mi-tv-stick/" target="_blank">
            <img src="@/assets/desktop/miStick.png" alt="">
            <div class="get-tv-icon-text">{{ $t('message.footer.miStick') }}</div>
          </a>
          <a class="icon-wrap" href="https://www.mi.com/global/list/" target="_blank">
            <img class="coming-soon-img" src="@/assets/desktop/comingSoon.png" alt="">
            <div class="get-tv-icon-text">{{ $t('message.footer.comingSoon') }}</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TvSwiper from './tvSwiper.vue'
export default {
  name: 'GetTV',
  components: {
    TvSwiper
  }
}
</script>

<style lang="scss" scoped>
.get-tv-wrap{
  width: 100%;
  overflow: hidden;
  background-color: #000000;
  .get-tv{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .get-tv-title{
      font-family: MiSans;
      font-size: 60px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0px;
      color: #FFFFFF;
      margin-top: 137px;
    }
    .goto-link{
      margin-top: 18px;
      margin-bottom: 73px;
    }
    .getTv{
      width: 100%;
      margin-top: 96px;
    }
    .get-tv-footer{
      width: 100%;
      background-color: #1E1E22;
      display: flex;
      flex-direction: column;
      align-items: center;
      .get-tv-footer-title{
        font-family: MiSans;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        color: #FFFFFF;
        margin: 40px 0px;
      }
      .get-tv-footer-line{
        width: 778px;
        height: 3px;
        background-color: #313131;
        margin-bottom: 28px;
      }
      .get-tv-footer-icon{
        width: 778px;
        height: 150px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 47px;
        .icon-wrap{
          height: 150px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-end;
          .coming-soon-img{
            margin-bottom: 19px;
          }
        }
        .get-tv-icon-text{
          font-family: MiSans;
          font-size: 21px;
          font-style: normal;
          font-weight: 400;
          color: #FFFFFF;
          margin-top: 26px;
        }
      }
    }
  }
}
</style>