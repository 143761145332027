<template>
  <div class="tv-swiper-wrap">
    <div class="tv-bg">
      <img class="getTv" src="@/assets/mobile/getTv.png" alt="">
    </div>
    <swiper ref="mySwiper" :options="swiperOptions" class="tvswiper-container">
      <swiper-slide>
        <img class="tv-swiper-img" src="@/assets/mobile/tv-one.png" alt="">
      </swiper-slide>
      <swiper-slide>
        <img class="tv-swiper-img" src="@/assets/mobile/tv-two.png" alt="">
      </swiper-slide>
      <swiper-slide>
        <img class="tv-swiper-img" src="@/assets/mobile/tv-three.png" alt="">
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  name: 'TvSwiper',
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
        },
        autoplay: {
          delay:3000,
          stopOnLastSlide:false,
          disableOnInteraction: false,
        },
      }
    }
  },
  mounted () {
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.tv-swiper-wrap{
  width: 1080px;
  position: relative;
  .getTv{
    width: 1080px;
    height: 669px;
  }
  .tvswiper-container{
    width: 800px;
    height: 500px;
    position: absolute;
    top: 42px;
    left: 140px;
    
    .tv-swiper-img{
      width: 800px;
      height: 500px;
    }
    
  }
  
}
</style>