<template>
  <div v-if="showTop" class="header-top-wrap">
    <div class="header-top-inner">
      <div class="header-tip" >
        {{ $t('message.header.titleTip') }}
      </div>
      <img @click="hideTop" class="close-btn" src="@/assets/close.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderTop',
  data() {
    return {
      showTop: true,
    }
  },
  methods: {
    hideTop () {
      this.showTop = false
    }
  },
}
</script>

<style lang="scss" scoped>
.header-top-wrap{
  width: 1080px;
  height: 152px;
  background: #424245;
  display: flex;
  align-items: center;
  justify-content: center;
  .header-top-inner{
    width: 960px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header-tip{
    width: 903px;
    color: rgba($color: #FFFFFF, $alpha: 0.6);
    font-family: MiSans;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    position: relative;
  }
  .close-btn{
    width: 16px;
    height: 15px;
    cursor: pointer;
  }
}

</style>
