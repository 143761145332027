import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  // locale: (navigator.language || navigator.userLanguage).substr(0, 2),
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    zh: require('./lang/zh-cn'),
    en: require('./lang/en-us'),
  },
})
