<template>
  <div class="tv-swiper-wrap">
    <div class="tv-bg">
      <img class="getTv" src="@/assets/desktop/getTv.png" alt="">
    </div>
    <swiper ref="mySwiper" :options="swiperOptions">
      <swiper-slide>
        <img src="@/assets/desktop/tv-one.png" alt="">
      </swiper-slide>
      <swiper-slide>
        <img src="@/assets/desktop/tv-two.png" alt="">
      </swiper-slide>
      <swiper-slide>
        <img src="@/assets/desktop/tv-three.png" alt="">
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  name: 'TvSwiper',
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
        },
        autoplay: {
          delay:3000,
          stopOnLastSlide:false,
          disableOnInteraction: false,
        },
      }
    }
  },
  mounted () {
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.tv-swiper-wrap{
  width: 100%;
  position: relative;

  .swiper-container{
    width: 1080px;
    height: 610px;
    position: absolute;
    top: 51px;
    left: 183px;
    .swiper-slide{
      width: 100%;
      img{
        width: 100%;
      }
    }
  }
}
</style>