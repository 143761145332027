<template>
  <div class="header-nav-wrap">
    <div class="header-nav-inner">
      <div class="header-nav">
        <div class="header-nav-item" @click="navHdl(index)" :class="index === 1 ? 'channelNavNorm' : 'channel-item-nochannels'" v-for="(nav, index) in navList" :key="index">
          {{ nav.name }}
          <img class="channels-icon" v-show="index === 1" src="@/assets/arrow-down.svg" />
          <transition name="fade">
            <div v-show="index === 1 && isShowChannelList" class="channel-wrap" >
              <div @click.stop="hideChannelList" class="channelNav">
                {{ nav.name }}
                <img class="channels-icon-inner" src="@/assets/arrow-down.svg" />
              </div>
              <div class="channel-item" v-for="(channel, channelIndex) in channelList" :key="channelIndex">
                <span @click="channelScroll(channelIndex)">{{ channel.name }}</span>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderNav',
  data() {
    return {
      navList: [],
      channelList: [],
      showChannel: false,
      isShowChannelList: false,
      imgArr: [],
    }
  },
  props:{
    mySwiper:{
      type: Object,
      default(){
        return {}
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.channelList = [
        {
          name: this.$t('message.header.bloom_berg'),
        },
        {
          name: this.$t('message.header.tasteMade'),
        },
        {
          name: this.$t('message.header.rakutenViki'),
        },
        {
          name: this.$t('message.header.loupe'),
        },
        {
          name: this.$t('message.header.myZenFit'),
        },
        {
          name: this.$t('message.header.failarmy'),
        },
        {
          name: this.$t('message.header.toonGoggles'),
        },
      ]
      this.navList = [
        {
          name: this.$t('message.header.whatTv'),
        },
        {
          name: this.$t('message.header.channels'),
        },
        {
          name: this.$t('message.header.watchFree'),
        },
        {
          name: this.$t('message.header.faqs'),
        },
      ]
    },
    channelScroll(channelIndex) {
      this.mySwiper.slideTo(channelIndex + 1, 1500,false)
    },
    hideChannelList() {
      this.isShowChannelList = false
    },
    navHdl(navIndex) {
      if(navIndex === 1){
        this.isShowChannelList = true
      } else if(navIndex === 0){
        this.mySwiper.slideTo(0, 1500,false)
      } else if(navIndex === 2){
        this.mySwiper.slideTo(8, 1500,false)
      } else {
        this.mySwiper.slideTo(9, 1500,false)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  .header-nav-wrap{
    width: 1080px;
    height: 85px;
    background: #202020;
    display: flex;
    justify-content: center;
    align-items: center;
    .header-nav-inner{
      width: 960px;
      height: 80px;
    }
    img{
      width: 20px;
      height: 20px;
    }
    .header-nav{
      width: 960px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .header-nav-item{
        color: #CECDCD;
        font-family: MiSans;
        font-style: normal;
        font-weight: normal;
        font-size: 30px;
        font-weight: 400;
        cursor: pointer;
        position: relative;
      }
      .channel-item-nochannels:hover{
        color: #FF6900;
      }
      .channel-wrap{
        width: 270px;
        height: 600px;
        background-color: #202020;
        color: #FFFFFF;
        position: absolute;
        top: 0px;
        left: -72px;
        .channel-item{
          width: 100%;
          height: 75px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .channel-item:hover{
          background: #323232;
          span{
            color: #FF6900;
          }
        }
      }
    }
  }
  .channelNavNorm{
    align-self: flex-end;
    text-align: center;
    line-height: 80px;
    position: relative;
  }
  .channels-icon{
    position: absolute;
    top: 33px;
    right: -28px;
  }
  .channels-icon-inner{
    position: absolute;
    top: 33px;
    right: 44px;
  }
  .channelNav{
    width: 270px;
    height: 75px;
    font-weight: 400;
    align-self: flex-end;
    text-align: center;
    line-height: 75px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    border-top: 3px solid #FF6900;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

</style>
